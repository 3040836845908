import axios from "axios";
import { genSignature } from "@/plugins/gen";
const state = () => ({
  items: [],
  textt: "test",
});

const mutations = {
  SET_SELECTED_ITEM(state, item) {
    state.items = item;
    // console.log("state.items", state.items);s
  },
};
const actions = {
  Downl({ commit }) {
    let mySignature;
    genSignature("/api/v1/website/linkdownload/view").then((res) => {
      mySignature = res;
      // Signature------------------------------------------------------------------------------------------------------------>
      // console.log("downLoader Sg: ", mySignature);
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/website/linkdownload/view`, {
            headers: {
              "X-SIGNATURE-APP": mySignature,
            },
          })
          .then((resp) => {
            // respon Api--------------------------------------------------------------------------------------------------->
            // console.log("resp", resp);
            commit("SET_SELECTED_ITEM", resp.data.resultData);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    });
  },
  async fetchBannerData() {
    try {
      const mySignature = await genSignature(
        "/api/v1/website/linkdownload/view"
      );
      let response = await axios.get("/api/website/linkdownload/view", {
        headers: {
          "X-SIGNATURE-APP": mySignature,
        },
      });
      this.items = response.data.resultData;
    } catch (error) {
      console.error("Error!", error);
    }
  },
};

const getters = {
  items: (state) => state.items,
  roundSix: (state) => state.roundSix,
  textt: (state) => state.textt,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
